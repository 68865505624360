<template>
  <div v-cus-loading="loading.main" class="container">
    <div class="current current-plan">
      <div class="col col-select">
        <div class="card">
          <div class="card-header">
            <h2 class="title">
              {{ company.planName }}
            </h2>
            <p class="price">
              {{ company.companyServicePrice | formatToCurrencyWithIsoCode }}
            </p>
            <span class="description"></span>
            <div class="line"></div>
          </div>
          <table class="features" width="100%">
            <tbody>
              <tr
                v-for="(itemFeature, indexFeature) in getFeatures(company.features)"
                :key="indexFeature"
              >
                <td align="center">
                  <span
                    v-if="
                      itemFeature.featureType === 'access' && itemFeature.featureValue === 'true'
                    "
                    class="tag success"
                  >
                    <i class="fad fa-check fa-lg"></i>
                  </span>
                  <span
                    v-if="
                      itemFeature.featureType === 'access' && itemFeature.featureValue === 'false'
                    "
                    class="tag danger"
                  >
                    <i class="fad fa-times fa-lg"></i>
                  </span>
                  <span
                    v-if="itemFeature.featureType !== 'access' && itemFeature.featureValue > 0"
                    class="tag success"
                  >
                    {{ itemFeature.featureValue }}
                  </span>
                  <span
                    v-if="itemFeature.featureType !== 'access' && itemFeature.featureValue < 0"
                    class="tag primary"
                  >
                    {{ $tc('unlimited') }}
                  </span>
                  <span v-if="+itemFeature.featureValue === 0" class="tag danger">
                    <i class="fad fa-times fa-lg"></i>
                  </span>
                </td>
                <td>
                  {{ itemFeature.featureName }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* Vendors
-------------------------------------------------- */

/* End of Vendors
-------------------------------------------------- */

export default {
  name: 'PlansCurrent',
  components: {},
  props: {
    company: { type: Object }
  },
  data() {
    return {
      loading: {
        main: false
      },
      models: {
        plan: []
      },
      rules: {}
    }
  },
  methods: {
    getFeatures(features) {
      if (features && +features.length) {
        return features
          .filter(item => !(+item.featureValue === 0))
          .sort((a, b) => {
            const valueA =
              a.featureValue === 'true'
                ? 1
                : a.featureValue === 'false'
                ? -1
                : +a.featureValue < 0
                ? 1000000000
                : +a.featureValue
            const valueB =
              b.featureValue === 'false'
                ? -1
                : b.featureValue === 'true'
                ? 1
                : +b.featureValue < 0
                ? 1000000000
                : +b.featureValue

            return valueB - valueA
          })
      }

      return []
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/transactions/select-plan.scss';
.col {
  width: auto;
  .features {
    margin: 2px auto;
  }
}
</style>
